import {Address, FreeTextAnswerKeys, Internal, TranslationSource} from "../enums/answers";
import {KitType} from "../enums/element";
import {ApiChangeTypes} from "../enums/publicApi";
import {ChangeType} from "../enums/queue";
import type {JSONObject, JSONQuestion} from "../utils";
import type {APIEntityInfo} from "./entity";
import {ListUser} from "./user";

export interface IdPair {
  connectionId?: string | null;
  entityId: string;
}

export interface IdPairDate extends IdPair {
  syncDate?: Date;
}

export interface UpdatedEntity {
  entityId: string;
  updateDate: string;
}

export interface AnswerKeys {
  [key: string]:
    | true
    | {
        [key: string]: true;
      };
}

export interface EditAnswerData {
  currentAnswer: any;
  answerKey: string;
  answerPath: string;
  answers: any;
  tree: JSONQuestion;
  question: JSONQuestion;
  savingEntity: string;
}
export interface AnswerSearchParams {
  answerKeys: AnswerKeys;
  page?: number;
  limit?: number;
  search?: string;
  sort?: {[field: string]: number};
  sortByEntityIn?: boolean;
  searchKeys?: string[];
  // each filter set is $or'd together, filter sets are and'd
  filters?: Array<{[answerKey: string]: string | number}>;
  entityNotIn?: string[];
  entityIn?: string[];
  filtersCaseSensitive?: boolean;
  includeSkeletons?: boolean;
  filterDeletedInstances?: boolean;
  includeAudit?: boolean;
  includeConnection?: boolean;
}

export interface Filters {
  [answerKey: string]: string;
}

export interface AnswerQuery {
  ids: IdPair[];
  answerKeys: AnswerKeys;
  includeDeleted?: boolean;
}

export interface Answers {
  entityId: string;
  connectionId?: string;
  [answerKey: string]: any;
}

interface LocaleTextObject {
  date: string;
  value: string;
}

export interface LocaleObject {
  [FreeTextAnswerKeys.ANSWER_LOCALE]: string;
  [key: string]: string | LocaleTextObject;
}

export interface AnswerSearchResults {
  search?: string;
  total: number;
  searchAfter?: string[];
  page: number;
  limit: number;
  results: Answers[];
}

export interface PatchAnswers {
  answers: JSONObject & {updatedBy: string; updatedByEntity: string};
  noAddGroups?: string[];
  groupUniqueKeys?: {
    [groupAnswerKey: string]: string[];
  };
  groupReferenceAnswerKeys?: {
    [answerKey: string]: string; // Answer key to referenced group
  };
  groupSortKeys?: {
    [groupAnswerKey: string]: GroupSortData[];
  };
  freeTextKeysData?: FreeTextKeyParams[];
  noUpdateChangeDates?: boolean;
  generateInstanceIds?: boolean;
}

export interface GroupSortData {
  key: string;
  ascend: boolean;
}

export interface PatchTranslation {
  answers: JSONObject;
  freeTextKeysData: FreeTextKeyParams[];
}

export interface FreeTextKeyParams {
  key: string;
  locale: string;
  fromTranslation?: boolean;
  translationSource?: TranslationSource;
  translator?: string;
  groupKey?: string;
  instanceId?: string;
}

export interface TranslationAnswerInfo {
  answerKey: string;
  groupKey?: string;
  instanceId?: string;
  translationLocale: string;
  translationSource: TranslationSource;
  translator?: string;
}

export interface ChangeConnectionEntityParams {
  oldEntityId: string;
  newEntityId: string;
  connectionId: string;
  updatedBy: string;
}

export interface HistoryQuery {
  answerKeys?: AnswerKeys;
  trackingKeys?: AnswerKeys;
  instanceId?: string;
  levels?: number;
  before?: Date;
  after?: Date;
  changedBy?: string;
}

export interface CondensedHistoryQuery extends HistoryQuery {
  since?: Date;
  changeTypes?: ApiChangeTypes[];
  options?: CondensedHistoryQueryOptions;
}

export interface CondensedHistoryQueryOptions {
  withChangeTypes?: boolean;
  includeBefore?: boolean;
  onlyConnectionData?: boolean;
  onlyEntityData?: boolean;
  rawHistory?: boolean;
  includeUntrackedChanges?: boolean;
  includeMetadata?: boolean;
}

export interface CondensedAnswerHistory extends Answers {
  updatedAt: Date;
}

export interface GroupChangeHistory {
  [instanceId: string]: {
    changeType: ChangeType;
    before: {
      [k: string]: any;
    };
    after: {
      [k: string]: any;
    };
  };
}

export interface AnswerChangeHistory {
  before: {
    [k: string]: any;
  };
  after: {
    [k: string]: any;
  };
  groups: {
    [groupKey: string]: GroupChangeHistory;
  };
  updatedBy: string;
  updatedByEmail: string;
  updatedByCreatedAt: Date | string;
  updatedById?: string;
  updatedByEntity: string;
  updatedByImage?: string;
  updatedAt: Date | string;
  updatedByDeleted?: boolean;
  acknowledgedBy?: string;
  acknowledgedByImage?: string;
  acknowledgedAt?: Date | string; // String for UI, since over ajax it gets converted to string. Probably a better way...
  acknowledgedByEmail: string;
  acknowledgedByCreatedAt: Date | string;
  acknowledgedByDeleted?: boolean;
}

export interface AnswerChangeHistoryResults {
  results: AnswerChangeHistory[];
}

export interface RecentChangesQuery {
  pertainingToEntityId: string;
  connectionId: string;
  acknowledgmentElementId: string;
}

export interface APIAnswers {
  company: APIEntityInfo;
  changes: {[k: string]: any};
}

export interface GetAnswers {
  questions: AnswerKeys;
  profileGroups?: string[];
}

export interface GetAnswerHistoryParams {
  connectionId?: string;
  pertainingToEntityId: string;
  acknowledgmentElementId?: string;
  kitType?: KitType;
  kitId?: string;
  query: HistoryQuery;
}

export interface KitId {
  kitType: KitType;
  entityId: string;
  kitId?: string;
}

export interface AddressForm {
  [Address.Line1]: string;
  [Address.Line2]: string;
  [Address.City]: string;
  [Address.RegionState]: string;
  [Address.Subdivision]?: string;
  [Address.SubSubdivision]?: string;
  [Address.Country]: string;
  [Address.PostalCode]: string;
}

export interface AddressObject extends AddressForm {
  [Address.Data]: string;
  validationType: string;
  validated: boolean;
  complete: boolean;
  autoSelected: boolean;
  forceSupplied: boolean;
  valid: boolean;
}

export interface GroupChangeData {
  answerKeys: string[];
  instanceId: string;
  groupKey: string;
  deleted: boolean;
}

export type AnswerDocId = IdPair | KitId;

export function isKitId(id: AnswerDocId): id is KitId {
  return "kitType" in id;
}

export interface Pointer {
  groupKey?: string;
  instanceId?: string;
}

export interface ConnectionPointer extends Pointer {
  entityId: string;
  connectionId: string;
}

export interface KitPointer extends Pointer {
  kitType: KitType;
  kitId: string;
}

export function isConnectionPointer(pointer: Pointer): pointer is ConnectionPointer {
  return Boolean((pointer as ConnectionPointer).connectionId && (pointer as ConnectionPointer).entityId);
}

export function isKitPointer(pointer: Pointer): pointer is KitPointer {
  return Boolean((pointer as KitPointer).kitType && (pointer as KitPointer).kitId);
}

export function isLocalPointer(pointer: Pointer): boolean {
  return !isConnectionPointer(pointer) && !isKitPointer(pointer);
}

export function toLocalPointer(pointer: Pointer): Pointer {
  return {
    groupKey: pointer.groupKey,
    instanceId: pointer.instanceId,
  };
}

export interface SegregatedAnswers {
  entityId: string;
  connectionId?: string;
  entityAnswers: Answers;
  connectionAnswers?: Answers;
}

export interface AnswerHistoryPatch extends JSONObject {
  updatedBy: string;
  updatedByEntity: string;
}

export interface ContactAnswer {
  userId: string;
  isPrimary: boolean;
}

export interface HydratedContactAnswer extends ContactAnswer {
  [Internal.HYDRATED_ANSWER_KEY]: ListUser;
}

export interface CondensedAnswerMetadata {
  updatedBy?: string;
  updatedAt?: string;
}

export interface AnswerMetadata {
  question?: {
    label?: string;
    topic?: string;
    type: string;
    isCalculated: boolean;
  };
  risk?: string;
  score?: string;
  overrideScore?: string;
  overrideRisk?: string;
  overrideNotes?: string;
  overrideBy?: {
    name: string;
    email: string;
    _id: string;
  };
  overrideOn?: string;
  lastUpdatedBy?: {
    name: string;
    email: string;
    _id: string;
  };
  maxScore?: string;
  lastUpdateOn?: string;
}
