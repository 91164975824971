<template>
  <GraphitePopover
    v-bind="{...$props, ...$attrs}"
    @show="$emit('show')"
    @hide="$emit('hide')"
    :targetElement="triggerElem"
    :triggerEvents="['hover']"
  >
    <div v-if="multiMode" ref="wrapperTriggerElem">
      <slot />
    </div>
    <span v-else-if="textMode" ref="wrapperTriggerElem">
      <slot />
    </span>
    <template v-else-if="singleSlotMode">
      <RenderNode :vnode="defaultSlots[0]" />
    </template>
    <template v-else>
      <slot />
    </template>

    <template #popover>
      <slot name="tooltip" />
    </template>
  </GraphitePopover>
</template>

<script lang="ts" setup>
import type {Placement} from "@floating-ui/core";
import type {PropType, VNode} from "vue";
import {Comment, computed, defineComponent, defineOptions, ref, Text, useSlots} from "vue";

const props = defineProps<{
  placement?: Placement;
  maxWidth?: number | string;
  // provide a default slot(s) OR a trigger elem, but not both (passed in will override)
  triggerElement?: HTMLElement;
  disabled?: boolean;
}>();

const emit = defineEmits<{
  hide: [];
  show: [];
}>();

const RenderNode = defineComponent({
  name: "RenderNode",
  props: {vnode: Object as PropType<VNode>},
  render() {
    //@ts-ignore // TODO: Find another way to do this that doesn't offend TS
    return this.vnode as VNode;
  },
  mounted() {
    singleSlotModeTriggerElem.value = this.$el;
  },
});

const slots = useSlots();
const singleSlotModeTriggerElem = ref<HTMLElement | null>(null);
const wrapperTriggerElem = ref<HTMLElement | null>(null);

const defaultSlots = computed(() => {
  return slots.default?.()?.filter((vnode) => vnode.type !== Comment) || [];
});

const singleSlotMode = computed(() => {
  return defaultSlots.value.length === 1;
});

const multiMode = computed(() => {
  return defaultSlots.value.length > 1;
});

const textMode = computed(() => {
  return singleSlotMode.value && defaultSlots.value.filter((vnode) => vnode.type === Text).length > 0;
});

const triggerElem = computed<HTMLElement | null>(() => {
  if (props.triggerElement) {
    return props.triggerElement;
  }

  if (multiMode.value || textMode.value) {
    return wrapperTriggerElem.value;
  }

  if (singleSlotMode.value) {
    return singleSlotModeTriggerElem.value;
  }

  return props.triggerElement;
});

defineOptions({
  inheritAttrs: false,
  compatConfig: {
    MODE: 3,
  },
});
</script>

<style lang="less" scoped>
// styles
</style>
