<template>
  <div class="graphite-checkbox-control">
    <div class="flex align-items-center checkbox-wrap" :class="wrapClass">
      <InputSwitch
        class="graphite-checkbox-switch"
        :disabled="disabled"
        :size="size"
        v-model="internalCheckedValue"
        :inputId="inputId"
        @update:modelValue="onInput"
        v-if="isSwitchMode"
        :pt="{hiddenInput: {tabindex}}"
        :aria-label="ariaLabel"
      />
      <TriStateCheckbox
        class="graphite-checkbox-indeterminate"
        :disabled="disabled"
        :size="size"
        :modelValue="triStateValue"
        :inputId="inputId"
        @update:modelValue="onInput(!internalCheckedValue)"
        v-else-if="isTriStateMode"
        :pt="{hiddenInput: {tabindex}}"
        :aria-label="ariaLabel"
      >
        <template #uncheckicon>
          <svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            class="p-icon p-checkbox-icon"
            aria-hidden="true"
            data-pc-section="checkboxicon"
          >
            <path
              d="M13.2222 7.77778H0.777778C0.571498 7.77778 0.373667 7.69584 0.227806 7.54998C0.0819442 7.40412 0 7.20629 0 7.00001C0 6.79373 0.0819442 6.5959 0.227806 6.45003C0.373667 6.30417 0.571498 6.22223 0.777778 6.22223H13.2222C13.4285 6.22223 13.6263 6.30417 13.7722 6.45003C13.9181 6.5959 14 6.79373 14 7.00001C14 7.20629 13.9181 7.40412 13.7722 7.54998C13.6263 7.69584 13.4285 7.77778 13.2222 7.77778Z"
              fill="currentColor"
            ></path>
          </svg>
        </template>
      </TriStateCheckbox>
      <Checkbox
        class="graphite-checkbox-group-item"
        v-else-if="isGroupMode"
        :disabled="disabled"
        :size="size"
        v-model="arrayModelValue"
        :binary="false"
        :value="value"
        :inputId="inputId"
        @update:modelValue="triggerGroupChange(arrayModelValue)"
        :pt="{hiddenInput: {tabindex}}"
        :aria-label="ariaLabel"
      />
      <Checkbox
        class="graphite-checkbox"
        v-else
        :disabled="disabled"
        :size="size"
        v-model="internalCheckedValue"
        :binary="true"
        :inputId="inputId"
        @change="stopEvent"
        @update:modelValue="onInput"
        :pt="{hiddenInput: {tabindex}}"
        :aria-label="ariaLabel"
      />
      <label class="checkbox-label flex" :for="inputId" ref="labelElemRef" v-if="hideIfInLabel">
        <slot>
          {{ label }}
        </slot>
      </label>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {CheckboxGroupTriggerChangeKey, CheckboxGroupValueKey} from "@/composables/checkbox/injections";
import {randomHex} from "pg-isomorphic/utils";
import type {PropType} from "vue";
import {computed, defineEmits, defineProps, inject, nextTick, ref, watch} from "vue";
import InputSwitch from "primevue/inputswitch";
import TriStateCheckbox from "primevue/tristatecheckbox";
import Checkbox from "primevue/checkbox";

const inputId = randomHex();

// if I wasn't super explicit with the defaults being `undefined`, it kept setting some to `false`, which caused issues
const props = defineProps({
  modelValue: {
    type: [Object, Boolean, String] as PropType<any | undefined>,
    default: () => undefined,
  },
  // this SHOULD be called "checkedValue" and is NOT the `modelValue`
  value: {
    type: [Object, String, Boolean] as PropType<any | undefined>,
    default: () => undefined,
  },
  uncheckedValue: {
    type: [Object, String, Boolean] as PropType<any | undefined>,
    default: () => undefined,
  },
  // you can also provide the label in the slot (which overrides this property)
  label: {
    type: String as PropType<string | undefined>,
    default: () => undefined,
  },
  checked: {
    type: Boolean as PropType<boolean | undefined>,
    default: () => undefined,
  },
  indeterminate: {
    type: Boolean as PropType<boolean | undefined>,
    default: () => undefined,
  },
  disabled: {
    type: Boolean as PropType<boolean | undefined>,
    default: () => undefined,
  },
  size: {
    type: String as PropType<string | undefined>,
    default: () => undefined,
  },
  switch: {
    type: Boolean as PropType<boolean | undefined>,
    default: () => undefined,
  },
  variant: {
    type: String as PropType<string | undefined>,
    default: () => undefined,
  },
  tabindex: {
    type: Number as PropType<number | undefined>,
    default: 0,
  },
  ariaLabel: {
    type: String as PropType<string | undefined>,
    default: () => undefined,
  },
});

const emit = defineEmits<{
  /** @deprecated use `update:modelValue` */
  (event: "input", val?: string | boolean | any): void;
  (event: "update:modelValue", val?: string | boolean | any): void;
  (event: "change", val?: string | boolean | any): void;
}>();

// these 2 are for when used in an checkbox group
const arrayModelValue = inject(CheckboxGroupValueKey, ref(null));
const triggerGroupChange = inject(CheckboxGroupTriggerChangeKey, () => {});
const isGroupMode = computed(() => arrayModelValue.value !== null);

const isSwitchMode = computed(() => !!props.switch);
const isTriStateMode = computed(() => props.indeterminate !== undefined);
const isBooleanMode = computed(() => isTriStateMode.value || isSwitchMode.value || props.value === undefined);

const labelElemRef = ref<HTMLElement | null>(null);
const hideIfInLabel = computed(() => {
  if (!labelElemRef.value) {
    return true;
  }

  // let curr = labelElemRef.value;
  // while (curr.pr)
  return true;
});

const outputValue = computed(() => {
  if (isBooleanMode.value) {
    return internalCheckedValue.value;
  }

  return internalCheckedValue.value ? props.value ?? true : props.uncheckedValue ?? false;
});

const inputValue = computed<boolean>(() => {
  if (isBooleanMode.value) {
    return props.modelValue ?? props.checked;
  }

  return props.checked;
});

const internalCheckedValue = ref<boolean>(inputValue.value);

const triStateValue = computed<boolean | null>(() => {
  if (isTriStateMode.value && props.indeterminate) {
    // false means X (which we're using an indeterminate...)
    return false;
  }

  // null means empty
  return inputValue.value || null;
});

const wrapClass = computed(() => {
  if (isSwitchMode.value) {
    return "switch-wrap";
  } else if (isTriStateMode.value) {
    return "indeterminate-wrap";
  } else if (isGroupMode.value) {
    return "group-item-wrap";
  } else {
    return "normal-wrap";
  }
});

watch(internalCheckedValue, () => {
  if (isGroupMode.value) {
    return;
  }

  emit("update:modelValue", outputValue.value);
  // legacy
  emit("input", outputValue.value);
});

watch(inputValue, (val: boolean) => {
  if (isGroupMode.value) {
    return;
  }

  internalCheckedValue.value = val;
});

function onInput(val: boolean) {
  internalCheckedValue.value = val;
  emit("change", outputValue.value);
}

function stopEvent(evt: Event) {
  evt?.preventDefault?.();
  evt?.stopPropagation?.();
}
</script>

<style lang="less" scoped>
.checkbox-label {
  cursor: pointer;
  margin: 0 0 0 0.5rem;
}

.switch-wrap {
  margin: 0.5rem 0;
}
</style>
