import {handleXhrError} from "@/composables/errorHandling";
import {RegisteredErrors} from "@/composables/errorHandling/registeredErrors";
import {httpGet, httpPost} from "@/composables/xhr";
import type {AxiosResponse} from "axios";
import type {BarePublicIdEntitiesAndConnections, BasicEntityInfo, ExtendedEntityInfo} from "pg-isomorphic/api/entity";
import type {SAMLLogQuery, SAMLLogQueryResult} from "pg-isomorphic/api/logs";
import type {EntitySignUpRole} from "pg-isomorphic/api/role";
import type {ConnectionDocument} from "pg-isomorphic/api/search";
import type {ConnectionRole} from "pg-isomorphic/enums";
import type {CacheItemData} from "pg-isomorphic/enums/cache";
import type {Dictionary} from "ramda";
import {clone, find, has} from "ramda";

export async function getEntity(entityId: string) {
  try {
    const response: AxiosResponse<ExtendedEntityInfo> = await httpGet(`/api/entities/${entityId}`);
    return response.data;
  } catch (e) {
    handleXhrError(RegisteredErrors.GET_ENTITY, `Could not retrieve entity: ${entityId}`, e);
  }
}

export async function getBasicEntity(entityId: string): Promise<BasicEntityInfo> {
  try {
    const response: AxiosResponse<BasicEntityInfo> = await httpGet(`/api/entities/info/${entityId}`);
    return response.data;
  } catch (e) {
    handleXhrError(RegisteredErrors.GET_ENTITY, `Could not retrieve entity: ${entityId}`, e);
  }
}

export async function getSignUpRoles(entityId: string, lang: string) {
  try {
    const response: AxiosResponse<EntitySignUpRole[]> = await httpGet(`/api/entities/${entityId}/signup_roles`, {
      locale: lang,
    });
    return response.data;
  } catch (e) {
    handleXhrError(RegisteredErrors.SIGN_UP_ROLES, `Could not retrieve signup roles for entity: ${entityId}`, e);
  }
}

export async function getSAMLLogs(query: SAMLLogQuery): Promise<SAMLLogQueryResult> {
  try {
    const response: AxiosResponse<SAMLLogQueryResult> = await httpPost(`/api/admin/entities/samllogs/query`, query);
    return response.data;
  } catch (e) {
    handleXhrError(RegisteredErrors.GLOBAL, `Could not retrieve SAML logs`, e);
  }
}

export async function getStatsData(entityId: string): Promise<Dictionary<CacheItemData>> {
  try {
    const response: AxiosResponse<Dictionary<CacheItemData>> = await httpGet(`/api/entities/${entityId}/statsdata`, {});
    return response.data;
  } catch (e) {
    handleXhrError(RegisteredErrors.SIGN_UP_ROLES, `Could not retrieve signup roles for entity: ${entityId}`, e);
  }
}

export async function getEntitiesByPublicIds(publicIds: string[]) {
  try {
    const response: AxiosResponse<BarePublicIdEntitiesAndConnections> = await httpPost(`/api/entities/public_ids`, {
      publicIds,
    });
    return response.data;
  } catch (e) {
    handleXhrError(RegisteredErrors.GLOBAL, `Could not retrieve entities`, e);
  }
}

/**
 * Filter entity connection results to just those that match the expected role (or alternatively are not connected)
 * @param connections
 * @param role
 * @param includePublic
 */
export function filterNonCollapsedResults(
  connections: ConnectionDocument[],
  role: ConnectionRole,
  includePublic = true,
): ConnectionDocument[] {
  const ogResults = clone(connections);
  return connections.filter((c) => {
    const roleRecord = find((o) => c.entityId === o.entityId && o.pertainingToEntityRole === role, ogResults);
    const noRoleRecord = find(
      (o) => c.entityId === o.entityId && o.connectionId && o.pertainingToEntityRole !== role,
      ogResults,
    );

    if (c.connectionId && c.pertainingToEntityRole === role) {
      return true;
    } else if (c.connectionId && !roleRecord) {
      return true;
    } else if (includePublic && !has("connectionId", c) && !roleRecord && !noRoleRecord) {
      return true;
    }

    return false;
  });
}
