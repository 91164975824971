<template>
  <div class="graphite-table-wrap" ref="wrapElemRef">
    <div class="top-scrollbar-wrap" ref="topScrollbarElemRef" :class="{'fake-hide': hideTopScroll}">
      <div class="top-scrollbar" :style="`width: ${tableWidth}px`"></div>
    </div>
    <DataTable
      ref="dataTableElemRef"
      :value="items"
      :stripedRows="striped"
      :class="`graphite-table-translation p-datatable-${sizeClass}`"
      @rowClick="rowClickHandler"
      :sortField="sortBy"
      :sortOrder="sortOrder"
      :dataKey="primaryKey"
      :loading="busy"
      :rowClass="getRowStyle"
      :rowHover="hover"
      :tableClass="`${fixed ? 'graphite-table-fixed' : ''} ${tableClass}`"
      :filters="filters"
      :pt="{
        thead: {
          class: theadClass,
        },
        tbody: {
          class: tbodyClass,
        },
        wrapper: {
          ...(disableHorizontalScroll && {style: 'overflow: visible;'}),
        },
      }"
    >
      <template v-if="emptyText || hasEmptySlot || showEmpty" #empty>
        <slot name="empty">
          {{ emptyText || "There are no records to show" }}
        </slot>
      </template>
      <Column v-if="!columns.length" :hidden="true" />
      <Column
        v-for="(field, index) in columns"
        :key="index"
        :field="field.key"
        :sortable="!!field.sortable"
        :class="field.class"
      >
        <template #sorticon=""></template>
        <template #header="">
          <div @click="handleHeaderClick(field)">
            <slot name="head()" :label="field.label" :column="field.key" :field="field.field">
              <slot :name="`head(${field.key})`" :label="field.label" :column="field.key" :field="field.field">
                {{ field.label }}
              </slot>
            </slot>
          </div>
        </template>
        <template #body="slotProps">
          <slot
            v-if="slotProps.field"
            name="cell()"
            :index="slotProps.index"
            :item="slotProps.data"
            :field="field.field"
          >
            <slot
              :name="`cell(${slotProps.field})`"
              :item="slotProps.data"
              :value="applyFormatter(slotProps)"
              :index="slotProps.index"
              :unformatted="slotProps.data[slotProps.field]"
            >
              {{ applyFormatter(slotProps) }}
            </slot>
          </slot>
        </template>
      </Column>
    </DataTable>
  </div>
</template>

<script lang="ts" setup>
import type {Dictionary} from "lodash";
import isFunction from "lodash/isFunction";
import isString from "lodash/isString";
import isUndefined from "lodash/isUndefined";
import upperFirst from "lodash/upperFirst";
import Column from "primevue/column";
import type {DataTableFilterMeta, DataTableRowClickEvent} from "primevue/datatable";
import DataTable from "primevue/datatable";
import type {ComputedRef, PropType, Ref} from "vue";
import {computed, defineEmits, defineProps, onBeforeUnmount, onMounted, ref, toRefs, useSlots, watch} from "vue";

export interface HeaderParams {
  key: string;
  label: string;
  sortable: boolean;
  field?: HeaderParams;
  class?: string;
}

const dataTableElemRef = ref<{$el: HTMLElement}>();
const tableElemRef = computed<HTMLElement>(() => dataTableElemRef.value.$el.querySelector(".p-datatable-wrapper"));
const topScrollbarElemRef = ref<HTMLElement>();
const wrapElemRef = ref<HTMLElement>();

const tableWidth = ref(0);
const containerWidth = ref(0);

// PROPS
const props = defineProps({
  responsive: {
    // We just swallow this
    type: Boolean,
    default: undefined,
  },
  items: {
    type: Array as PropType<any>,
    default: () => [],
  },
  fields: {
    type: Array as PropType<any>,
    default: () => [],
  },
  striped: {
    type: Boolean,
    default: false,
  },
  small: {
    type: Boolean,
    default: false,
  },
  emptyText: {
    type: String,
    default: "",
  },
  showEmpty: {
    type: Boolean,
    default: null,
  },
  sortBy: {
    type: String,
    default: undefined,
  },
  sortDesc: {
    type: Boolean,
    default: false,
  },
  primaryKey: {
    type: String,
    default: undefined,
  },
  busy: {
    type: Boolean,
    default: undefined,
  },
  tbodyTrClass: {
    type: [String, Array, Function],
    default: undefined,
  },
  hover: {
    type: Boolean,
    default: false,
  },
  theadClass: {
    type: String,
    default: "",
  },
  tbodyClass: {
    type: String,
    default: "",
  },
  tableClass: {
    type: String,
    default: "",
  },
  fixed: {
    type: Boolean,
    default: false,
  },
  filter: {
    type: String,
    default: "",
  },
  disableHorizontalScroll: {
    type: Boolean,
    default: false,
  },
});
const {fields, filter, items, small, sortBy, sortDesc, tbodyTrClass} = toRefs(props);
const slots = useSlots();
const hasSlot = (name) => {
  return !!slots[name];
};

// EMITTED EVENTS
const emit = defineEmits<{
  (event: "rowClicked", item: any, index: number, clickEvent: any): void;
  (event: "update:sortBy", name: string): void;
  (event: "update:sortDesc", desc: boolean): void;
}>();

const sortOrder = computed(() => (sortDesc.value ? -1 : 1));

function handleHeaderClick(headerInfo: HeaderParams) {
  if (headerInfo.sortable) {
    emit("update:sortBy", headerInfo.key);
    emit("update:sortDesc", !sortDesc.value);
  }
}

function getRowStyle(data) {
  if (tbodyTrClass.value) {
    if (isFunction(tbodyTrClass.value)) {
      return tbodyTrClass.value(data);
    }
    return tbodyTrClass.value;
  }
  return undefined;
}

function rowStyle() {
  return {fontWeight: "bold", fontStyle: "italic"};
}

function applyFormatter(slotData) {
  if (formatters.value[slotData.field]) {
    return formatters.value[slotData.field](slotData.data[slotData.field], slotData.field, slotData.data);
  } else {
    return slotData.data[slotData.field] === false ? "" : slotData.data[slotData.field];
  }
}

function rowClickHandler(event: DataTableRowClickEvent) {
  // Currently doesn't support middle arg, index
  emit("rowClicked", event.data, 0, event.originalEvent);
}

const formatters: Ref<Dictionary<(fieldData, fieldName, allData) => any>> = ref({});
const columns: Ref<HeaderParams[]> = ref([]);
watch(
  () => [fields, items],
  () => {
    if (!items.value || !items.value.length) {
      return;
    }
    if (!fields.value.length) {
      const firstRow = items.value[0];
      const firstRowKeys = Object.keys(firstRow);

      columns.value = firstRowKeys.map((k) => ({
        label: upperFirst(k),
        key: k,
        sortable: false,
      }));
    } else {
      const firstField = fields.value[0];
      if (isString(firstField)) {
        columns.value = fields.value.map((f) => ({
          label: upperFirst(f),
          key: f,
          sortable: false,
        }));
      } else {
        const returnFields = [];
        for (const f of fields.value) {
          if (f.formatter) {
            formatters.value[f.key] = f.formatter;
          }
          returnFields.push({
            label: !isUndefined(f.label) ? f.label : upperFirst(f.key),
            key: f.key,
            sortable: !!f.sortable,
            field: f,
            class: f.class,
          });
        }
        columns.value = returnFields;
      }
    }
  },
  {
    immediate: true,
    deep: true,
  },
);

const sizeClass = computed(() => {
  if (small.value) {
    return "sm";
  }
  return "";
});

const filters: ComputedRef<DataTableFilterMeta> = computed(() => {
  return {global: {value: filter.value}} as unknown as DataTableFilterMeta;
});

const hasEmptySlot = computed(() => {
  return hasSlot("empty");
});

const hideTopScroll = computed(() => {
  return tableWidth.value <= containerWidth.value;
});

const resizeObserver = new ResizeObserver((entries: ResizeObserverEntry[]) => {
  tableWidth.value = tableElemRef.value.scrollWidth;
  containerWidth.value = wrapElemRef.value.clientWidth;
});

// super hacky scrollbar at the top of the table
onMounted(() => {
  tableElemRef.value.addEventListener("scroll", copyLowerScrollToUpper);
  topScrollbarElemRef.value.addEventListener("scroll", copyUpperScrollToLower);
  tableWidth.value = tableElemRef.value.scrollWidth;
  resizeObserver.observe(tableElemRef.value);
});

onBeforeUnmount(() => {
  tableElemRef.value.removeEventListener("scroll", copyLowerScrollToUpper);
  topScrollbarElemRef.value.removeEventListener("scroll", copyUpperScrollToLower);
  resizeObserver.unobserve(tableElemRef.value);
});

function copyUpperScrollToLower() {
  tableElemRef.value.scrollLeft = topScrollbarElemRef.value.scrollLeft;
}

function copyLowerScrollToUpper() {
  topScrollbarElemRef.value.scrollLeft = tableElemRef.value.scrollLeft;
}
</script>

<style lang="less" scoped>
@import "@/less/global.less";

.graphite-table-translation {
  margin-bottom: 0;
  :deep {
    .graphite-table-fixed {
      table-layout: fixed;
    }

    .p-datatable-thead {
      top: 0;
    }
  }
}

.graphite-table-wrap {
  position: relative;
}

.top-scrollbar-wrap {
  &.fake-hide {
    height: 0 !important;
  }

  background: @grey50;
  position: sticky;
  top: 0;
  overflow-x: auto;
  overflow-y: hidden;
  height: 20px;
  z-index: 2;

  .top-scrollbar {
    width: 1000px;
  }
}
</style>
