import type {PGActionContext, PGActionTree} from "@/store/index-types";
import cloneDeep from "lodash/cloneDeep";
import {Profile} from "pg-isomorphic/enums";
import {ExpressionEvaluator} from "pg-isomorphic/rules/expression";
import {sleep} from "pg-isomorphic/utils";
import {path, pluck, uniq} from "ramda";
import globalLogger from "../../logging";
import {autoMutations} from "../utils";

const logger = globalLogger.getLogger("two-factor");

interface TwoFactorState {
  loading: boolean;
  reVerifyLoading: boolean;
  verified: boolean; // This means user is 2fa auth'd and their identity is verified.
  verifyingTopic: string;
  verifiedTopics: {};
  siblings: any[];
  connectionId: string;
  cacheSpecificAnswers: {};
  currentProfile: Profile;
  show2fa: boolean;
  nonProfile: boolean;
  onVerified: () => Promise<void>;
  onSuccessAcknowledge: null;
  onCancel: null;
  getAndUpdateSpecificAnswers: {};
  hideChange: boolean;
  securedForApproval: boolean;
}

const state: TwoFactorState = {
  loading: false,
  reVerifyLoading: false,
  verified: false,
  verifyingTopic: "",
  verifiedTopics: {},
  siblings: [],
  connectionId: "",
  cacheSpecificAnswers: {},
  currentProfile: Profile.MINE,
  show2fa: false,
  nonProfile: false,
  onVerified: null,
  onSuccessAcknowledge: null,
  onCancel: null,
  getAndUpdateSpecificAnswers: {},
  hideChange: false,
  securedForApproval: false,
};

type Ctx = PGActionContext<TwoFactorState>;

const getters = {};

function getAnswerKeysWithCalcDeps(siblings: any[]) {
  const siblingKeys = pluck("key", siblings);
  const keys = [];
  siblingKeys.map((s) => {
    const parts = s.split(".");
    if (parts.length > 1) {
      // it's a group_instance key
      keys.push(parts[0]); // group name
      keys.push(parts[2]); // actual key
    } else {
      keys.push(s);
    }
  });
  siblings.map((s) => {
    if (s.calculation) {
      const calcDeps = new ExpressionEvaluator(s.calculation).answerKeys;
      keys.push(...calcDeps);
    }
  });
  return uniq(keys);
}

const actions: PGActionTree<TwoFactorState> = {
  async init(
    {commit, rootState, state}: Ctx,
    {siblings, topic, currentProfile, connectionId, onVerified, onSuccessAcknowledge, onCancel, securedForApproval},
  ) {
    commit("connectionId", connectionId);
    commit("currentProfile", currentProfile);
    commit("nonProfile", false);
    const siblingKeys = getAnswerKeysWithCalcDeps(siblings);
    commit("siblings", siblingKeys);
    logger.debug(`init 2fa topic ${topic}`);
    logger.debug(`init 2fa siblings ${siblingKeys}`);

    const alreadyVerified = path(["user", "twoFactorAuthVerified"], rootState);
    const treatUserAsIdentityVerified = path(["user", "treatUserAsIdentityVerified"], rootState);
    commit("verifyingTopic", topic);
    logger.debug(
      `init 2fa: alreadyVerified=${alreadyVerified} treatUserAsIdentityVerified=${treatUserAsIdentityVerified}`,
    );

    if (alreadyVerified && treatUserAsIdentityVerified) {
      commit("reVerifyLoading", true);
      commit("show2fa", true);
      await sleep(50); // Give time for a loading indicator to render
      let topics = state.verifiedTopics;
      if (state.verifiedTopics[topic]) {
        topics[topic] = false;
        commit("verifiedTopics", cloneDeep(topics));
      } else {
        topics[topic] = true;
        commit("verifiedTopics", cloneDeep(topics));

        // check if we've pulled answers for this topic
        let cached = state.cacheSpecificAnswers;
        logger.debug(`cached ${JSON.stringify(cached)}`);
        if (!cached[topic]) {
          commit("getAndUpdateSpecificAnswers", {
            answerKeys: siblingKeys,
            whose: state.currentProfile,
            connectionId,
          });
          cached[topic] = true;
          commit("cacheSpecificAnswers", cloneDeep(cached));
        }
      }
      commit("verified", true);
      commit("show2fa", false);
      commit("reVerifyLoading", false);
      return;
    }

    commit("show2fa", true);
    commit("loading", true);
    commit("onVerified", onVerified);
    commit("onSuccessAcknowledge", onSuccessAcknowledge);
    commit("onCancel", onCancel);
    commit("securedForApproval", securedForApproval);

    commit("loading", false);
  },

  async initNonProfile({commit, rootState}, {onVerified, onCancel, onSuccessAcknowledge, hideChange}) {
    logger.debug(`init non profile`);
    const alreadyVerified = path(["user", "twoFactorAuthVerified"], rootState);
    const treatUserAsIdentityVerified = path(["user", "treatUserAsIdentityVerified"], rootState);
    logger.debug(
      `init non profile: alreadyVerified=${alreadyVerified} treatUserAsIdentityVerified=${treatUserAsIdentityVerified}`,
    );
    commit("nonProfile", true);
    commit("onVerified", onVerified);
    commit("onSuccessAcknowledge", onSuccessAcknowledge);
    commit("onCancel", onCancel);
    commit("hideChange", hideChange);
    commit("securedForApproval", false);
    if (alreadyVerified && treatUserAsIdentityVerified) {
      commit("verified", true);
      if (onVerified) {
        onVerified();
      }
    } else {
      commit("show2fa", true);
    }
  },
  async finishVerification({commit, state}) {
    logger.debug(`2fa finishVerification`);
    if (!state.nonProfile) {
      commit("getAndUpdateSpecificAnswers", {
        answerKeys: state.siblings,
        whose: state.currentProfile,
        connectionId: state.connectionId,
      });
      commit("cacheSpecificAnswers", {[state.verifyingTopic]: true});
    }

    if (state.onVerified) {
      await state.onVerified();
    }

    let topics = state.verifiedTopics;
    topics[state.verifyingTopic] = true;
    commit("verifiedTopics", cloneDeep(topics));

    // reset everything
    commit("verifyingTopic", "");
    commit("verified", true);

    state.show2fa = false;
  },
  reset({commit}) {
    commit("verifiedTopics", {});
  },
};

const mutations = {
  ...autoMutations(state),
};

export default {
  namespaced: true,
  getters,
  state,
  actions,
  mutations,
};
