import "./less/graphite-general.less";

import GraphiteAlert from "@/components/common/GraphiteAlert.vue";
import GraphiteBadge from "@/components/common/GraphiteBadge.vue";
import GraphiteButton from "@/components/common/GraphiteButton.vue";
import GraphiteCheckbox from "@/components/common/GraphiteCheckbox.vue";
import GraphiteCheckboxGroup from "@/components/common/GraphiteCheckboxGroup.vue";
import GraphiteCol from "@/components/common/GraphiteCol.vue";
import GraphiteCollapse from "@/components/common/GraphiteCollapse.vue";
import GraphiteContainer from "@/components/common/GraphiteContainer.vue";
import GraphitePaginator from "@/components/common/GraphitePaginator.vue";
import GraphitePopover from "@/components/common/GraphitePopover.vue";
import GraphiteProgress from "@/components/common/GraphiteProgress.vue";
import GraphiteRadio from "@/components/common/GraphiteRadio.vue";
import GraphiteRadioGroup from "@/components/common/GraphiteRadioGroup.vue";
import GraphiteRow from "@/components/common/GraphiteRow.vue";
import GraphiteSimpleFile from "@/components/common/GraphiteSimpleFile.vue";
import GraphiteTooltip from "@/components/common/GraphiteTooltip.vue";
import GraphiteTooltipDirective from "@/components/common/GraphiteTooltipDirective";
import GraphiteModal from "@/components/modal/GraphiteModal.vue";
import GraphiteModalDirective from "@/components/modal/GraphiteModalDirective";
import GraphiteTable from "@/components/table/GraphiteTable.vue";
import {getStore} from "@/composables/get.store";
import {mixpanelTrack} from "@/utils/mixpanel";
import {createUpload} from "@websanova/vue-upload";
import httpAxios from "@websanova/vue-upload/dist/drivers/http/axios.esm.js";
import axios from "axios";
import ConfirmationService from "primevue/confirmationservice";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css";
import "filepond/dist/filepond.min.css";
import Cookies from "js-cookie";
import {vMaska} from "maska";
import PortalVue from "portal-vue";
import type {PrimeVueConfiguration} from "primevue/config";
import PrimeVue from "primevue/config";
import "primevue/resources/primevue.min.css";
import "primevue/resources/themes/viva-light/theme.css";
import ToastService from "primevue/toastservice";
import {pathOr} from "ramda";
import {createApp} from "vue";
import VueGtag from "vue-gtag";
import VueObserveVisibility from "vue-observe-visibility";
import type {RouteLocationNormalized} from "vue-router";
import App from "./App.vue";
import QuestionInput from "./components/questions/QuestionInput.vue"; // We use some recursion so this has to be frontloaded
import Sidebar from "./components/shared/Sidebar.vue"; // This is a drop-in replacement for `b-bootstrap`, so we make it global for consistency
import {setupFontAwesome} from "./fontAwesome";
import {i18n, i18nRoot} from "./i18n";
import logger from "./logging";
import {router} from "./router";

import "primevue/resources/primevue.min.css";
import "./assets/themes/mytheme/theme.scss";
import "primeflex/primeflex.min.css";

const store = getStore();

function bootstrap() {
  const requestedPath = Cookies.get("ssoRequestedPath");
  if (requestedPath) {
    store.commit("requestedPath", requestedPath);
  }

  const app = createApp(App);

  // Kick off authentication check first thing
  store
    .dispatch("checkAuthentication")
    .then(() => {
      const env = pathOr(null, ["state", "envConfig"], store);
      const gaCode = env?.gaCode;

      if (gaCode) {
        app.use(
          VueGtag,
          {
            config: {
              id: gaCode,
              params: {
                cookie_flags: "HttpOnly;Secure",
              },
            },
            enabled: env?.environmentName !== "local",
          },
          router,
        );
      }
    })
    .catch(() => {
      /* nothing */
    });

  const fa = setupFontAwesome();
  // const app = createApp({
  //   store,
  //   i18n,
  //   render: (h) => h(App),
  //   provide() {
  //     return {[RootKey]: this};
  //   },
  // });

  app.use(PrimeVue, {
    csp: {
      nonce: document.querySelector<HTMLMetaElement>("meta[property=csp-nonce]")?.content,
    },
    zIndex: {
      modal: 1032,
      overlay: 1032,
      menu: 1032,
      tooltip: 1032,
    },
  } as PrimeVueConfiguration);
  app.use(VueObserveVisibility);
  app.use(ToastService);
  app.use(router);
  app.use(i18nRoot);
  app.use(store);
  app.use(PortalVue);
  app.use(ConfirmationService);

  // To replace v-visible in vue2
  app.directive("visible", (el, binding) => {
    let value = binding.value;
    if (value) {
      el.style.visibility = "visible";
    } else {
      el.style.visibility = "hidden";
    }
  });

  // todo could move to its own file and just import the created thing
  app.use(
    createUpload({
      plugins: {
        http: axios,
      },
      drivers: {
        http: httpAxios,
      },
      options: {},
    }),
  );

  app.component("question-input", QuestionInput);
  app.component("GraphiteAlert", GraphiteAlert);
  app.component("GraphiteContainer", GraphiteContainer);
  app.component("GraphiteRow", GraphiteRow);
  app.component("GraphiteCol", GraphiteCol);
  app.component("GraphiteButton", GraphiteButton);
  app.component("GraphiteBadge", GraphiteBadge);
  app.component("GraphiteCheckbox", GraphiteCheckbox);
  app.component("GraphiteProgress", GraphiteProgress);
  app.component("GraphiteCheckboxGroup", GraphiteCheckboxGroup);
  app.component("GraphitePaginator", GraphitePaginator);
  app.component("GraphiteRadioGroup", GraphiteRadioGroup);
  app.component("GraphiteRadio", GraphiteRadio);
  app.component("GraphiteCollapse", GraphiteCollapse);
  app.component("GraphiteSimpleFile", GraphiteSimpleFile);
  app.component("GraphiteTooltip", GraphiteTooltip);
  app.component("GraphitePopover", GraphitePopover);
  app.component("g-sidebar", Sidebar);
  app.component("font-awesome-icon", fa);
  app.component("FontAwesomeIcon", fa);

  app.directive("maska", vMaska);

  app.directive("g-tooltip", GraphiteTooltipDirective);
  app.directive("g-modal", GraphiteModalDirective);

  app.component("GraphiteModal", GraphiteModal);
  app.component("GraphiteTable", GraphiteTable);

  // Register a global custom directive called v-focus to focus on the element when it gets inserted into the dom
  app.directive("focus", {
    mounted(el, binding) {
      // just the presence of `v-focus` will trigger it (but `v-focus="undefined"` won't)
      if (binding.value === true || !("value" in binding)) {
        el.focus();
      }
    },
  });

  // Useful for use in modals
  app.directive("delay-focus", {
    mounted(el, binding) {
      // just the presence of `v-delay-focus` will trigger it (but `v-delay-focus="undefined"` won't)
      const delay = binding.value === undefined || typeof binding.value === "number" ? binding.value : undefined;
      if (binding.value === true || binding.value === undefined || typeof binding.value === "number") {
        setTimeout(() => {
          el?.focus?.();
          el?.querySelector("input,textarea")?.focus();
        }, delay);
      }
    },
  });

  const HOSTNAME_PROD = "app.graphiteconnect.com";
  app.config.globalProperties.$HOSTNAME_PROD = HOSTNAME_PROD;
  app.config.globalProperties.$SUPPORT_EMAIL = "support@graphiteconnect.com";

  app.mount("#app");
}

store
  .dispatch("fetchEnvironmentConfig")
  .then(bootstrap)
  .catch((err) => {
    console.error("errsoidjf", err);
    bootstrap();
  });

// routes that don't require authentication are automatically allowed
const allowedResponderOnlyRoutes = [
  `/responder`,
  `/user/profile`,
  `/user/commprefs`,
  `/responder-sso-handler`,
  `/responder/admin/questions`,
];

/**
 * Save data to store, can affect the front end functionality so try and place right in front of next
 */
function saveRouterInfoToStore(to: RouteLocationNormalized, from: RouteLocationNormalized) {
  // the vue router doesn't play nice with the composition API, so we store a copy in the store on route change
  const toClone = {...to};
  // matched cat be very large and unneeded, so let's just discard it
  delete toClone.matched;
  store.commit("currentRoute", toClone);
  if (from?.path) {
    const fromClone = {...from};
    delete fromClone.matched;
    store.commit("previousRoute", fromClone);
  }
}

// BEFORE EACH ROUTE CHECK IF WE NEED TO SEND THEM TO LOGIN, CHANGE TITLE
router.beforeEach(async (to, from, next) => {
  const user = await store.dispatch("getCurrentUser");
  await store.dispatch("resetUserActivityTimer");
  const isAuthenticated = store.getters.isAuthenticated;
  const requiresAuth = to.meta.requiresAuth !== false ? true : to.meta.requiresAuth;
  const entity = to.query.entity;
  const ignoreRequestedPath = to.query.irp;

  if (store.state.haltRouter) {
    next(false);
    store.commit("haltRouterOpenDialog", true);
    store.commit("haltRouterNextMethod", () => {
      saveRouterInfoToStore(to, from);
      router.push(to.fullPath);
    });
    return;
  }
  saveRouterInfoToStore(to, from);

  if (to.path !== "/data_share" && user?.dataShareData?.ndaAcceptanceNeeded && !user?.dataShareData?.ndaAccepted) {
    return next(`/data_share?token=${from.query.token || to.query.token || ""}`);
  }

  if (!isAuthenticated && requiresAuth) {
    await store.dispatch("setRequestedPath", to.fullPath);
    await store.dispatch("setRequestedEntity", entity);
    if (entity) {
      await store.dispatch("checkSSO", {email: entity});
      if (store.state.ssoUrl?.length > 0) {
        window.location.href = store.state.ssoUrl;
        return;
      }
    }

    // if they're trying to hit responder directly, we are gonna redirect them to the Responder login
    // (both logins will work for both users, there's just Responder-specific language on this sign-in)
    if (window.location.pathname?.startsWith(`/responder`)) {
      return next("/responder/signin?r=requiresauth");
    } else if (to.path !== "signin") {
      return next("/signin?r=requiresauth");
    }
  } else if (isAuthenticated) {
    if (isAuthenticated && entity && entity !== user.activeEntityId) {
      logger.trace(() => `switch entity: ${user.activeEntityId} ==> ${entity}`);
      await store.dispatch("userProfile/setActiveEntity", {entityId: entity});
    }
    if (requiresAuth && user?.activeEntityIsResponderOnly && !allowedResponderOnlyRoutes.includes(to.path)) {
      next("/responder");
      return;
    }
    if (store.state.requestedPath && !ignoreRequestedPath) {
      const reqPath = store.state.requestedPath;
      await store.dispatch("setRequestedPath", "");
      next(reqPath);
      return;
    }
  }
  saveRouterInfoToStore(to, from);
  next();
});

router.afterEach(async (to) => {
  let title = "graphiteConnect";
  if (to.meta.title) {
    title += " - " + i18n.t(String(to.meta.title));
  }
  document.title = title;
  const mixTitle = to.meta.title ? i18n.t(String(to.meta.title)) : to.path;
  mixpanelTrack(`Page View: ${mixTitle}`, {Path: to.path});
});

if (window) {
  window.onerror = (message, file, line, col, error) => {
    const msg = ["Message: " + message, "URL: " + file, "Line: " + line, "Column: " + col].join(" - ");
    logger.error(msg, error);
  };
}
