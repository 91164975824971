import {getStore} from "@/composables/get.store";
import {getUserActiveEntityId} from "@/composables/vuex";
import {getCurrentRoute} from "@/router/util";
import type {ConnectionRole} from "pg-isomorphic/enums";
import {Profile} from "pg-isomorphic/enums";

export function getCurrentProfile(): Profile {
  return (getCurrentRoute().params.whichProfile as Profile | undefined) || Profile.MINE;
}

export function getCounterProfile(): Profile {
  return getCurrentProfile() === Profile.MINE ? Profile.THEIRS : Profile.MINE;
}

/**
 * @deprecated
 */
export function getRequestingEntityId(): string {
  return getStore().state?.profile?.connection?.requestingEntity;
}

/**
 * @deprecated
 */
export function getRespondingEntityId(): string {
  return getStore().state?.profile?.connection?.respondingEntity;
}

/**
 * @deprecated
 */
export function getRequestingEntityRole(): ConnectionRole {
  return getStore().state?.profile?.connection?.requestingRole;
}

/**
 * @deprecated
 */
export function getRespondingEntityRole(): ConnectionRole {
  return getStore().state?.profile?.connection?.respondingRole;
}

/**
 * @deprecated
 */
export function getCounterEntityId(): string | undefined {
  return (
    getUserActiveEntityId() &&
    (getUserActiveEntityId() === getRequestingEntityId() ? getRespondingEntityId() : getRequestingEntityId())
  );
}

/**
 * @deprecated
 */
export function getSelectedProfileEntityId(): string | undefined {
  return getCurrentProfile() === Profile.THEIRS ? getCounterEntityId() : getUserActiveEntityId();
}

/**
 * @deprecated
 */
export function getRoleForProfile(): ConnectionRole {
  return (
    getUserActiveEntityId() &&
    (getUserActiveEntityId() === getRequestingEntityId() ? getRequestingEntityRole() : getRespondingEntityRole())
  );
}
