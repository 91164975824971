import {both, curry} from "ramda";
import {ConnectionRole, SecurityRole, Stage, TopicReviewAnswerKeys} from "../enums";
import {EntityFeature} from "../enums/entity";
import {coerceToArray, JSONObject} from "../utils";

export enum Permission {
  // Non-production permissions
  DEMO_DATA = "DEMO_DATA",

  // graphite admin permissions
  CHECK_VERSION = "CHECK_VERSION",
  MANAGE_GRAPHITE_ADMINS = "MANAGE_GRAPHITE_ADMINS",
  MANAGE_SEARCH_INDEX = "MANAGE_SEARCH_INDEX",
  MANAGE_REDIRECT_INVITE = "MANAGE_REDIRECT_INVITE",
  MANAGE_SWITCHED_INVITE = "MANAGE_SWITCHED_INVITE",
  MANAGE_ANY_FIELD_VALIDATION = "MANAGE_ANY_FIELD_VALIDATION",
  MANAGE_VALIDATION_LOGS = "MANAGE_VALIDATION_LOGS",
  MANAGE_SYSTEM_PROFILING = "MANAGE_SYSTEM_PROFILING",
  ADD_USER_TO_ANY_ENTITY = "ADD_USER_TO_ANY_ENTITY",
  ADD_RELAY_API_KEY_USER = "ADD_RELAY_API_KEY_USER",
  VIEW_ANY_ENTITY_DATA = "VIEW_ANY_ENTITY_DATA",
  AUDIT_TASKS = "AUDIT_TASKS",
  USER_ADMIN_SKIP_2FA = "USER_ADMIN_SKIP_2FA",
  ADD_NEW_COMPANY = "ADD_NEW_COMPANY",
  ENABLE_ENTITY_FEATURES = "ENABLE_ENTITY_FEATURES",
  CONFIGURE_ANY_INTEGRATION = "CONFIGURE_ANY_INTEGRATION",
  MANAGE_ENTITY_GENERAL_SETTINGS = "MANAGE_ENTITY_GENERAL_SETTINGS",
  MANAGE_INTERNAL_ROLES = "MANAGE_INTERNAL_ROLES",
  MANAGE_ANY_WAREHOUSE = "MANAGE_ANY_WAREHOUSE",
  MANAGE_ANY_API_KEY = "MANAGE_ANY_API_KEY",
  MANAGE_QUEUE = "MANAGE_QUEUE",
  MANAGE_LOGGING = "MANAGE_LOGGING",
  BULK_INVITE = "BULK_INVITE",
  MANAGE_ANY_ENTITY_VISIBILITY = "MANAGE_ANY_ENTITY_VISIBILITY",
  CLEAR_CACHE = "CLEAR_CACHE",
  RESPONDER = "RESPONDER",
  DELETE_ANY_FILE = "DELETE_ANY_FILE",
  DELETE_ANY_ENTITY = "DELETE_ANY_ENTITY",
  DELETE_ANY_KIT = "DELETE_ANY_KIT",
  CLONE_ANY_ENTITY = "CLONE_ANY_ENTITY",
  DELETE_OR_UPDATE_ANY_ENTITY_MESSAGES = "DELETE_OR_UPDATE_ANY_ENTITY_MESSAGES",
  DEBUG_TRIGGERS = "DEBUG_TRIGGERS",
  BYPASS_READ_SECURITY_RULES = "BYPASS_READ_SECURITY_RULES",
  BYPASS_DUPLICATE_TAX_ID = "BYPASS_DUPLICATE_TAX_ID",
  BYPASS_ENTITY_ASSOCIATION = "BYPASS_ENTITY_ASSOCIATION",
  BULK_TIN_UPDATE = "BULK_TIN_UPDATE",
  RUN_ENTITY_STATS = "RUN_ENTITY_STATS",
  VIEW_ANY_ENTITY_FEATURE_USAGE = "VIEW_ANY_ENTITY_FEATURE_USAGE",
  REQUEUE_EVENTS = "REQEUE_EVENTS",

  // support permissions
  APPROVE_ANY_APPROVAL = "APPROVE_ANY_APPROVAL",
  MANAGE_ANY_ACTION_PLAN = "MANAGE_ANY_ACTION_PLAN",
  MANAGE_CAMPAIGNS_ENTITY_CONNECTION_ROLE = "MANAGE_CAMPAIGNS_ENTITY_CONNECTION_ROLE",
  NO_REALLY_COMPLETE_ANY_WORKFLOW_TASK = "NO_REALLY_COMPLETE_ANY_WORKFLOW_TASK",

  // entity admin permissions
  DELETE_USER = "DELETE_USER",
  APPROVE_USER = "APPROVE_USER",
  UPDATE_USER = "UPDATE_USER",
  INVITE_USER = "INVITE_USER", // SSO users can invite users too, dynamically added to SSO entity users permissions
  INVITE_NON_SSO_USER = "INVITE_NON_SSO_USER", // Stronger permission
  DELETE_ANY_MESSAGE = "DELETE_ANY_MESSAGE",
  COMPLETE_ANY_TOPIC_REVIEW = "COMPLETE_ANY_TOPIC_REVIEW", // Complete topic review as non-topic-owner
  COMPLETE_ANY_CONFIRMATION = "COMPLETE_ANY_CONFIRMATION",
  IGNORE_CONNECTION_ROLE = "IGNORE_CONNECTION_ROLE",
  MANAGE_ENTITY = "MANAGE_ENTITY",
  MANAGE_INTEGRATIONS = "MANAGE_INTEGRATIONS",
  VIEW_API_KEYS = "VIEW_API_KEYS",
  CREATE_API_KEYS = "CREATE_API_KEYS",
  DEACTIVATE_API_KEYS = "DEACTIVATE_API_KEYS",
  MANAGE_WEBHOOKS = "MANAGE_WEBHOOKS",
  MANAGE_USER_GROUPS = "MANAGE_USER_GROUPS",
  MANAGE_LOCALIZATION = "MANAGE_LOCALIZATION",
  UPDATE_TASKS = "UPDATE_TASKS", // can update note(comment), status, status reason, and assign owner
  UPDATE_LOGO = "UPDATE_LOGO",
  COMPLETE_ANY_REMINDER = "COMPLETE_ANY_REMINDER",
  COMPLETE_ANY_WORKFLOW_TASK = "COMPLETE_ANY_WORKFLOW_TASK",
  MANAGE_ACTION_PLAN = "MANAGE_ACTION_PLAN",
  MANAGE_NETSUITE_INTEGRATION = "MANAGE_NETSUITE_INTEGRATION",
  MANAGE_INTACCT_INTEGRATION = "MANAGE_INTACCT_INTEGRATION",
  MANAGE_ZENDESK_INTEGRATION = "MANAGE_ZENDESK_INTEGRATION",
  MANAGE_CAMPAIGNS = "MANAGE_CAMPAIGNS",
  SEND_BULK_COMMUNICATIONS = "SEND_BULK_COMMUNICATIONS",
  OVERRIDE_RESTRICT_MANUAL_APPROVAL = "OVERRIDE_RESTRICT_MANUAL_APPROVAL",
  ADMIN_REPORTS = "ADMIN_REPORTS",
  MANAGE_WAREHOUSE = "MANAGE_WAREHOUSE",
  APPROVE_ANY_CONNECTION_STAGE = "APPROVE_ANY_CONNECTION_STAGE",
  VIEW_USER_HISTORY = "VIEW_USER_HISTORY",
  BULK_TICKET = "BULK_TICKET",
  ADMIN_QUESTIONS = "ADMIN_QUESTIONS",
  VIEW_QUESTION_TREE = "VIEW_QUESTION_TREE",
  ADMIN_INTERFACES = "ADMIN_INTERFACES",
  VIEW_ENTITY_DATA = "VIEW_ENTITY_DATA",
  VIEW_ENTITY_FEATURE_USAGE = "VIEW_ENTITY_FEATURE_USAGE",
  MANAGE_ENTITY_INTEGRATION_LOGS = "MANAGE_ENTITY_INTEGRATION_LOGS",
  RUN_GIACT_REPORT = "RUN_GIACT_REPORT",
  MANAGE_ENTITY_CONNECTIONS = "MANAGE_ENTITY_CONNECTIONS",
  MANAGE_WORKFLOWS = "MANAGE_WORKFLOWS",

  MANAGE_COMPANY = "MANAGE_COMPANY",
  VIEW_MANAGE_COMPANY = "VIEW_MANAGE_COMPANY",

  // Admin/button owner permissions.
  APPROVE_INVITE_STAGE = "APPROVE_INVITE_STAGE",
  APPROVE_ACCEPT_STAGE = "APPROVE_ACCEPT_STAGE",
  APPROVE_COLLABORATE_STAGE = "APPROVE_COLLABORATE_STAGE",
  APPROVE_CONNECT_STAGE = "APPROVE_CONNECT_STAGE",
  DISCONNECT_CONNECTION = "DISCONNECT_CONNECTION",
  HIDE_ENTITY_PROFILE = "HIDE_ENTITY_PROFILE",
  UNHIDE_ENTITY_PROFILE = "UNHIDE_ENTITY_PROFILE",

  // Menu permissions
  MENU_HOME = "MENU_HOME",
  READ_TASKS = "READ_TASKS",
  MENU_CONNECTIONS = "MENU_CONNECTIONS",
  MENU_ACTION_PLANS = "MENU_ACTION_PLANS",
  MENU_ADMIN = "MENU_ADMIN",
  MENU_SEARCH = "MENU_SEARCH",
  READ_MESSAGES = "READ_MESSAGES",
  READ_NOTIFICATIONS = "READ_NOTIFICATIONS",
  MENU_COMPANIES = "MENU_COMPANIES",
  MENU_CREATE_COMPANY = "MENU_CREATE_COMPANY",
  MENU_MY_ACCOUNT = "MENU_MY_ACCOUNT",
  MENU_SIGN_UP = "MENU_SIGN_UP",
  READ_APPROVALS = "READ_APPROVALS",

  // side rail permissions
  SIDE_RAIL_EDIT = "SIDE_RAIL_EDIT",
  CREATE_REMINDERS = "CREATE_REMINDERS",
  CREATE_NOTES = "CREATE_NOTES",
  CREATE_MANUAL_TASKS = "CREATE_MANUAL_TASKS",
  CREATE_MESSAGES = "CREATE_MESSAGES",
  SIDE_RAIL_HISTORY = "SIDE_RAIL_HISTORY",
  SIDE_RAIL_DELETE_RECORD = "SIDE_RAIL_DELETE_RECORD",
  SIDE_RAIL_EXPORT = "SIDE_RAIL_EXPORT",

  // things ordinary users can maybe do
  CREATE_DELETE_CONNECTION = "CREATE_CONNECTION",
  WRITE_ANSWERS = "WRITE_ANSWERS",
  SEND_CLICK_SHARE = "SEND_CLICK_SHARE",
  ASSIGN_TASK = "ASSIGN_TASK",
  MANAGE_BUSINESS_APPROVALS = "MANAGE_BUSINESS_APPROVALS",
  WRITE_RESPONDER_QUESTIONS = "WRITE_RESPONDER_QUESTIONS",
  VIEW_RESPONDER_REPORTS = "VIEW_RESPONDER_REPORTS",
  VIEW_NPS_REPORTS = "VIEW_NPS_REPORTS",
  CREATE_ACTION_PLAN = "CREATE_ACTION_PLAN",
  MANAGE_REPORTS = "MANAGE_REPORTS",

  // Relay service permission
  MANAGE_RELAY_REQUEST = "MANAGE_RELAY_REQUEST",

  // Zendesk webhook service permission
  ZENDESK_WEBHOOK = "ZENDESK_WEBHOOK",

  // Loader permissions
  LOAD_QUESTIONS = "LOAD_QUESTIONS",
  LOAD_KIT_QUESTIONS = "LOAD_KIT_QUESTIONS",
  LOAD_OVERRIDES = "LOAD_OVERRIDES",
  LOAD_RISK = "LOAD_RISK_COLUMNS",
  LOAD_CAMPAIGN_COMPANIES = "LOAD_CAMPAIGN_COMPANIES",
  LOAD_KIT_COLUMNS = "LOAD_KIT_COLUMNS",
  LOAD_TABLES = "LOAD_TABLES",
  LOAD_TRANSLATION_TABLES = "LOAD_TRANSLATION_TABLES",
  LOAD_TRANSFORMATION_MAPPINGS = "LOAD_TRANSFORMATION_MAPPINGS",
  LOAD_TRANSFORMATIONS = "LOAD_TRANSFORMATIONS",
  LOAD_TRIGGERS = "LOAD_TRIGGERS",
  LOAD_ELEMENT_ACTIONS = "LOAD_ELEMENT_ACTIONS",
  LOAD_QUICK_LINKS = "LOAD_QUICK_LINKS",
  LOAD_WORKFLOWS = "LOAD_WORKFLOWS",
  LOAD_VISUAL_WORKFLOWS = "LOAD_VISUAL_WORKFLOWS",
  LOAD_CONNECTIONS = "LOAD_CONNECTIONS",
  LOAD_SEARCH_COLUMNS = "LOAD_SEARCH_COLUMNS",
  LOAD_KITS = "LOAD_KITS",
  LOAD_NETSUITE_LOOKUPS = "LOAD_NETSUITE_LOOKUPS",
  LOAD_NETSUITE_MAPPINGS = "LOAD_NETSUITE_MAPPINGS",
  LOAD_NETSUITE_SETTINGS = "LOAD_NETSUITE_SETTINGS",
  LOAD_INTACCT_LOOKUPS = "LOAD_INTACCT_LOOKUPS",
  LOAD_INTACCT_MAPPINGS = "LOAD_INTACCT_MAPPINGS",
  LOAD_INTACCT_SETTINGS = "LOAD_INTACCT_SETTINGS",
  LOAD_EXTERNAL_INTERFACES = "LOAD_EXTERNAL_INTERFACES",
  LOAD_VALUE_SETS = "LOAD_VALUE_SETS",
  LOAD_CARDS = "LOAD_CARDS",
  LOAD_CLIENT_TRIGGERS = "LOAD_CLIENT_TRIGGERS",
  LOAD_ANY_OBJECT_OWNER = "LOAD_ANY_OBJECT_OWNER",
  LOAD_BATCH_JOB = "LOAD_BATCH_JOB",
  LOAD_SECRETS = "LOAD_SECRETS",

  // List aka Value Set permissions (Value Sets was renamed Lists in most place)
  MANAGE_OWN_VALUE_SETS = "MANAGE_OWN_VALUE_SETS",
  CREATE_OWN_VALUE_SETS = "CREATE_OWN_VALUE_SETS", // should always pair with MANAGE_OWN_VALUE_SETS
  DELETE_OWN_VALUE_SETS = "DELETE_OWN_VALUE_SETS", // should always pair with CREATE_OWN_VALUE_SETS
  MANAGE_GRAPHITE_VALUE_SETS = "MANAGE_GRAPHITE_VALUE_SETS", // should always pair with the own value set perms
  CREATE_GRAPHITE_VALUE_SETS = "CREATE_GRAPHITE_VALUE_SETS", // should always pair with the own value set perms & MANAGE_GRAPHITE_VALUE_SETS
  DELETE_GRAPHITE_VALUE_SETS = "DELETE_GRAPHITE_VALUE_SETS", // should always pair with the own value set perms & CREATE_GRAPHITE_VALUE_SETS

  // view all message threads whether or not they are part of the thread
  VIEW_ALL_MESSAGE_THREADS_FOR_CONNECTION = "VIEW_ALL_MESSAGE_THREADS_FOR_CONNECTION",

  ASSIGN_CUSTOM_PERMISSIONS = "ASSIGN_CUSTOM_PERMISSIONS",
  RESET_USER_2FA = "RESET_USER_2FA",

  // Worker permissions
  IGNORE_DISALLOW_ADD_DELETE = "IGNORE_DISALLOW_ADD_DELETE",

  // Saved Searches (saved filters
  DELETE_SHARED_SAVED_SEARCH = "DELETE_SHARED_SAVED_SEARCH",
}

// permissions that can be assigned to groups in the admin (currently only bt graphite admins)
export const editablePermissions = [Permission.VIEW_ALL_MESSAGE_THREADS_FOR_CONNECTION];

export const allLoaderPermissions: Permission[] = [
  Permission.LOAD_QUESTIONS,
  Permission.LOAD_KIT_QUESTIONS,
  Permission.LOAD_OVERRIDES,
  Permission.LOAD_RISK,
  Permission.LOAD_CAMPAIGN_COMPANIES,
  Permission.LOAD_KIT_COLUMNS,
  Permission.LOAD_TABLES,
  Permission.LOAD_TRANSLATION_TABLES,
  Permission.LOAD_TRANSFORMATION_MAPPINGS,
  Permission.LOAD_TRANSFORMATIONS,
  Permission.LOAD_TRIGGERS,
  Permission.LOAD_ELEMENT_ACTIONS,
  Permission.LOAD_QUICK_LINKS,
  Permission.LOAD_WORKFLOWS,
  Permission.LOAD_VISUAL_WORKFLOWS,
  Permission.LOAD_CONNECTIONS,
  Permission.LOAD_SEARCH_COLUMNS,
  Permission.LOAD_KITS,
  Permission.LOAD_NETSUITE_LOOKUPS,
  Permission.LOAD_NETSUITE_MAPPINGS,
  Permission.LOAD_NETSUITE_SETTINGS,
  Permission.LOAD_INTACCT_LOOKUPS,
  Permission.LOAD_INTACCT_MAPPINGS,
  Permission.LOAD_INTACCT_SETTINGS,
  Permission.LOAD_EXTERNAL_INTERFACES,
  Permission.LOAD_VALUE_SETS,
  Permission.LOAD_CARDS,
  Permission.LOAD_CLIENT_TRIGGERS,
  Permission.LOAD_BATCH_JOB,
  Permission.LOAD_SECRETS,
];

export const defaultPermissions: {[k in SecurityRole]: Permission[]} = {
  [SecurityRole.GRAPHITE_THOR]: [
    Permission.ADMIN_QUESTIONS,
    Permission.LOAD_ANY_OBJECT_OWNER,
    ...allLoaderPermissions,
    Permission.CHECK_VERSION,
    Permission.DEBUG_TRIGGERS,
  ],
  [SecurityRole.GRAPHITE_CSR]: [
    Permission.ADD_NEW_COMPANY,
    Permission.ADMIN_REPORTS,
    Permission.BULK_INVITE,
    Permission.CREATE_GRAPHITE_VALUE_SETS,
    Permission.CREATE_OWN_VALUE_SETS,
    Permission.DELETE_ANY_FILE,
    Permission.DELETE_GRAPHITE_VALUE_SETS,
    Permission.DELETE_OR_UPDATE_ANY_ENTITY_MESSAGES,
    Permission.DELETE_OWN_VALUE_SETS,
    Permission.ENABLE_ENTITY_FEATURES,
    Permission.MANAGE_ANY_ACTION_PLAN,
    Permission.MANAGE_ANY_API_KEY,
    Permission.MANAGE_ANY_ENTITY_VISIBILITY,
    Permission.MANAGE_ANY_WAREHOUSE,
    Permission.MANAGE_ENTITY_GENERAL_SETTINGS,
    Permission.MANAGE_GRAPHITE_VALUE_SETS,
    Permission.MANAGE_INTERNAL_ROLES,
    Permission.MANAGE_OWN_VALUE_SETS,
    Permission.MANAGE_REDIRECT_INVITE,
    Permission.MANAGE_REPORTS,
    Permission.MANAGE_SWITCHED_INVITE,
    Permission.READ_MESSAGES,
    Permission.RESPONDER,
    Permission.SEND_BULK_COMMUNICATIONS,
    Permission.VIEW_QUESTION_TREE,
    Permission.ADD_RELAY_API_KEY_USER,
    Permission.ADD_USER_TO_ANY_ENTITY,
    Permission.VIEW_ANY_ENTITY_DATA,
    Permission.VIEW_NPS_REPORTS,
    Permission.AUDIT_TASKS,
    Permission.CHECK_VERSION,
    Permission.VIEW_ALL_MESSAGE_THREADS_FOR_CONNECTION,
    Permission.ASSIGN_CUSTOM_PERMISSIONS,
    Permission.RESET_USER_2FA,
    Permission.BYPASS_ENTITY_ASSOCIATION,
    Permission.BULK_TIN_UPDATE,
    Permission.RUN_ENTITY_STATS,
    Permission.VIEW_ANY_ENTITY_FEATURE_USAGE,
  ],
  [SecurityRole.GRAPHITE_INTERFACES]: [
    Permission.CONFIGURE_ANY_INTEGRATION,
    Permission.DEBUG_TRIGGERS,
    Permission.MANAGE_INTACCT_INTEGRATION,
    Permission.MANAGE_NETSUITE_INTEGRATION,
    Permission.MANAGE_ZENDESK_INTEGRATION,
    Permission.LOAD_EXTERNAL_INTERFACES,
    Permission.LOAD_TRANSFORMATIONS,
    Permission.LOAD_TRANSFORMATION_MAPPINGS,
    Permission.LOAD_TRANSLATION_TABLES,
    Permission.LOAD_VALUE_SETS,
    Permission.LOAD_NETSUITE_LOOKUPS,
    Permission.LOAD_NETSUITE_MAPPINGS,
    Permission.LOAD_NETSUITE_SETTINGS,
    Permission.LOAD_INTACCT_LOOKUPS,
    Permission.LOAD_INTACCT_MAPPINGS,
    Permission.LOAD_INTACCT_SETTINGS,
    Permission.LOAD_SECRETS,
  ],
  [SecurityRole.GRAPHITE_VALIDATOR]: [
    Permission.AUDIT_TASKS,
    Permission.MANAGE_ANY_FIELD_VALIDATION,
    Permission.MANAGE_VALIDATION_LOGS,
    Permission.USER_ADMIN_SKIP_2FA,
    Permission.VIEW_ANY_ENTITY_DATA,
    Permission.BYPASS_READ_SECURITY_RULES,
    Permission.BYPASS_DUPLICATE_TAX_ID,
  ],
  [SecurityRole.GRAPHITE_OPS]: [
    Permission.MANAGE_SEARCH_INDEX,
    Permission.MANAGE_SYSTEM_PROFILING,
    Permission.MANAGE_QUEUE,
    Permission.MANAGE_LOGGING,
    Permission.CHECK_VERSION,
  ],
  [SecurityRole.GRAPHITE_DATA]: [
    Permission.DEMO_DATA,
    Permission.CLONE_ANY_ENTITY,
    Permission.MANAGE_LOCALIZATION,
    Permission.CLEAR_CACHE,
    Permission.CHECK_VERSION,
    Permission.VIEW_RESPONDER_REPORTS,
    Permission.VIEW_NPS_REPORTS,
    Permission.DELETE_ANY_ENTITY,
    Permission.BYPASS_READ_SECURITY_RULES,
    Permission.BYPASS_DUPLICATE_TAX_ID,
    Permission.ADD_USER_TO_ANY_ENTITY,
    Permission.VIEW_ANY_ENTITY_FEATURE_USAGE,
    Permission.REQUEUE_EVENTS,
  ],
  [SecurityRole.GRAPHITE_SUPER]: [Permission.MANAGE_GRAPHITE_ADMINS],
  [SecurityRole.TRIGGER]: [Permission.IGNORE_DISALLOW_ADD_DELETE],
  [SecurityRole.ADMIN]: [
    Permission.ADMIN_REPORTS,
    Permission.APPROVE_ANY_CONNECTION_STAGE,
    Permission.APPROVE_INVITE_STAGE,
    Permission.BULK_TICKET,
    Permission.COMPLETE_ANY_REMINDER,
    Permission.COMPLETE_ANY_TOPIC_REVIEW,
    Permission.COMPLETE_ANY_WORKFLOW_TASK,
    Permission.DELETE_ANY_KIT,
    Permission.DELETE_ANY_MESSAGE,
    Permission.IGNORE_CONNECTION_ROLE,
    Permission.MANAGE_ACTION_PLAN,
    Permission.MENU_ADMIN,
    Permission.READ_MESSAGES,
    Permission.RUN_GIACT_REPORT,
    Permission.SEND_BULK_COMMUNICATIONS,
    Permission.UPDATE_LOGO,
    Permission.UPDATE_TASKS,
    Permission.VIEW_ALL_MESSAGE_THREADS_FOR_CONNECTION,
    Permission.VIEW_ENTITY_DATA,
    Permission.VIEW_ENTITY_FEATURE_USAGE,
    Permission.VIEW_MANAGE_COMPANY,
    Permission.MANAGE_ENTITY_CONNECTIONS,
    Permission.MANAGE_WORKFLOWS,
    Permission.DELETE_SHARED_SAVED_SEARCH,
  ],

  [SecurityRole.SYSTEM_ADMIN]: [
    Permission.ADMIN_REPORTS,
    Permission.APPROVE_USER,
    Permission.ASSIGN_CUSTOM_PERMISSIONS,
    Permission.BULK_TICKET,
    Permission.DELETE_ANY_KIT,
    Permission.DELETE_ANY_MESSAGE,
    Permission.DELETE_USER,
    Permission.INVITE_NON_SSO_USER,
    Permission.INVITE_USER,
    Permission.MANAGE_ACTION_PLAN,
    Permission.MANAGE_COMPANY,
    Permission.MANAGE_ENTITY,
    Permission.MANAGE_USER_GROUPS,
    Permission.MENU_ADMIN,
    Permission.READ_MESSAGES,
    Permission.RUN_GIACT_REPORT,
    Permission.SEND_BULK_COMMUNICATIONS,
    Permission.UNHIDE_ENTITY_PROFILE,
    Permission.UPDATE_LOGO,
    Permission.UPDATE_USER,
    Permission.VIEW_ALL_MESSAGE_THREADS_FOR_CONNECTION,
    Permission.VIEW_ENTITY_DATA,
    Permission.VIEW_ENTITY_FEATURE_USAGE,
    Permission.VIEW_MANAGE_COMPANY,
    Permission.VIEW_QUESTION_TREE,
    Permission.VIEW_USER_HISTORY,
    Permission.DELETE_SHARED_SAVED_SEARCH,
  ],

  [SecurityRole.IT_ADMIN]: [
    Permission.ADMIN_INTERFACES,
    Permission.ADMIN_REPORTS,
    Permission.CREATE_API_KEYS,
    Permission.DEACTIVATE_API_KEYS,
    Permission.LOAD_EXTERNAL_INTERFACES,
    Permission.LOAD_TRANSFORMATIONS,
    Permission.LOAD_TRANSFORMATION_MAPPINGS,
    Permission.LOAD_TRANSLATION_TABLES,
    Permission.LOAD_VALUE_SETS,
    Permission.LOAD_NETSUITE_LOOKUPS,
    Permission.LOAD_NETSUITE_MAPPINGS,
    Permission.LOAD_NETSUITE_SETTINGS,
    Permission.LOAD_INTACCT_LOOKUPS,
    Permission.LOAD_INTACCT_MAPPINGS,
    Permission.LOAD_INTACCT_SETTINGS,
    Permission.LOAD_SECRETS,
    Permission.MANAGE_COMPANY,
    Permission.MANAGE_ENTITY,
    Permission.MANAGE_ENTITY_INTEGRATION_LOGS,
    Permission.MANAGE_INTEGRATIONS,
    Permission.MANAGE_WAREHOUSE,
    Permission.MENU_ADMIN,
    Permission.VIEW_API_KEYS,
    Permission.VIEW_ENTITY_DATA,
    Permission.VIEW_QUESTION_TREE,
    Permission.DELETE_SHARED_SAVED_SEARCH,
  ],

  [SecurityRole.INVITE_BUTTON_OWNER]: [Permission.APPROVE_INVITE_STAGE],

  [SecurityRole.ACCEPT_BUTTON_OWNER]: [Permission.APPROVE_ACCEPT_STAGE],

  [SecurityRole.COLLABORATE_BUTTON_OWNER]: [Permission.APPROVE_COLLABORATE_STAGE],

  [SecurityRole.CONNECT_BUTTON_OWNER]: [Permission.APPROVE_CONNECT_STAGE],

  [SecurityRole.CANCEL_CONNECTION]: [Permission.DISCONNECT_CONNECTION],

  [SecurityRole.BULK_SUPPLIER_UPDATES]: [Permission.BULK_TICKET],

  [SecurityRole.ACTIVE_USER]: [
    Permission.MENU_HOME,
    Permission.READ_TASKS,
    Permission.MENU_CONNECTIONS,
    Permission.MENU_ACTION_PLANS,
    Permission.MENU_SEARCH,
    Permission.READ_MESSAGES,
    Permission.READ_NOTIFICATIONS,
    Permission.MENU_COMPANIES,
    Permission.MENU_CREATE_COMPANY,
    Permission.MENU_MY_ACCOUNT,
    Permission.SIDE_RAIL_EDIT,
    Permission.SIDE_RAIL_HISTORY,
    Permission.SIDE_RAIL_EXPORT,
    Permission.CREATE_MESSAGES,
    Permission.CREATE_NOTES,
    Permission.CREATE_MANUAL_TASKS,
    Permission.CREATE_REMINDERS,
    Permission.SIDE_RAIL_DELETE_RECORD,
    Permission.CREATE_DELETE_CONNECTION,
    Permission.WRITE_ANSWERS,
    Permission.SEND_CLICK_SHARE,
    Permission.ASSIGN_TASK,
    Permission.MANAGE_BUSINESS_APPROVALS,
    Permission.WRITE_RESPONDER_QUESTIONS,
    Permission.CREATE_ACTION_PLAN,
    Permission.READ_APPROVALS,
  ],

  [SecurityRole.EXTERNAL_USER]: [Permission.MENU_SIGN_UP],

  [SecurityRole.RELAY_SERVICE]: [Permission.MANAGE_RELAY_REQUEST],

  [SecurityRole.BANKING]: [Permission.RUN_GIACT_REPORT],

  [SecurityRole.TAX]: [],

  [SecurityRole.CAMPAIGNS]: [Permission.MANAGE_CAMPAIGNS],
  [SecurityRole.BULK_COMMUNICATIONS]: [Permission.SEND_BULK_COMMUNICATIONS],

  [SecurityRole.RESPONDER_USER]: [Permission.WRITE_RESPONDER_QUESTIONS, Permission.MENU_COMPANIES],

  [SecurityRole.RESPONDER_REPORT_USER]: [Permission.VIEW_RESPONDER_REPORTS],

  [SecurityRole.NPS_REPORT_USER]: [Permission.VIEW_NPS_REPORTS],

  [SecurityRole.SUPPORT]: [
    Permission.APPROVE_ANY_APPROVAL,
    Permission.MANAGE_CAMPAIGNS,
    Permission.IGNORE_CONNECTION_ROLE,
    Permission.MANAGE_ANY_ACTION_PLAN,
    Permission.MANAGE_ANY_FIELD_VALIDATION,
    Permission.COMPLETE_ANY_CONFIRMATION,
    Permission.MANAGE_ENTITY_GENERAL_SETTINGS,
    Permission.OVERRIDE_RESTRICT_MANUAL_APPROVAL,
    Permission.SEND_BULK_COMMUNICATIONS,
    Permission.READ_NOTIFICATIONS,
    Permission.MANAGE_CAMPAIGNS_ENTITY_CONNECTION_ROLE,
    Permission.APPROVE_ANY_CONNECTION_STAGE,
    Permission.NO_REALLY_COMPLETE_ANY_WORKFLOW_TASK,
    Permission.MANAGE_OWN_VALUE_SETS,
    Permission.CREATE_OWN_VALUE_SETS,
    Permission.DELETE_OWN_VALUE_SETS,
  ],

  [SecurityRole.ZENDESK]: [Permission.ZENDESK_WEBHOOK],
};

export const adminAccessPermissions = [
  Permission.DELETE_USER,
  Permission.APPROVE_USER,
  Permission.UPDATE_USER,
  // Permission.INVITE_USER,
  Permission.MANAGE_ANY_FIELD_VALIDATION,
  Permission.VIEW_MANAGE_COMPANY,
  Permission.VIEW_API_KEYS,
  Permission.CREATE_API_KEYS,
  Permission.DEACTIVATE_API_KEYS,
  Permission.MANAGE_USER_GROUPS,
];

export interface UserPermissions {
  permissions: Set<Permission>;
  topicOwnerRoles: Set<string>;
  activeEntityActiveConnectionRole?: ConnectionRole;
}

export const check = curry((permission: Permission, userPermissions: UserPermissions) => {
  if (!userPermissions.permissions) {
    return false;
  }
  return userPermissions.permissions.has(permission);
});

export const checkConnectionRole = curry(
  (connectionRole: ConnectionRole | undefined, userPermissions: UserPermissions) =>
    Boolean(connectionRole) &&
    (userPermissions.activeEntityActiveConnectionRole === connectionRole ||
      userPermissions.activeEntityActiveConnectionRole === ConnectionRole.BOTH ||
      check(Permission.IGNORE_CONNECTION_ROLE, userPermissions)),
);

export const checkPermissionAndConnectionRole = curry(
  (permission: Permission, connectionRole: ConnectionRole | undefined, userPermissions: UserPermissions) =>
    both(check(permission), checkConnectionRole(connectionRole))(userPermissions),
);

export const anyAdminPermission = (user: UserPermissions) => hasPermission(adminAccessPermissions, user);

export const anyLoaderPermission = (user: UserPermissions) => hasPermission(allLoaderPermissions, user);

export const hasSuperAdminPermission = (user: UserPermissions) =>
  hasPermission([Permission.VIEW_ANY_ENTITY_DATA], user);

export const hasPermission = (permissions: Permission | Permission[], user: UserPermissions) =>
  coerceToArray(permissions).some((p) => check(p, user));

export const hasAllPermissions = (permissions: Permission[], user: UserPermissions) =>
  permissions.every((p) => check(p, user));

export const canCompleteTopicReview = (
  topicOwnerRole: string,
  connectionRole: ConnectionRole | undefined,
  user: UserPermissions & {_id?: any},
  topicReviewKey?: string,
  topicReviewInstanceId?: string,
  answers?: JSONObject,
) =>
  check(Permission.COMPLETE_ANY_TOPIC_REVIEW, user) ||
  (checkConnectionRole(connectionRole, user) && user.topicOwnerRoles.has(topicOwnerRole)) ||
  answers?.[topicReviewKey!]?.[topicReviewInstanceId!]?.[TopicReviewAnswerKeys.OWNER] === String(user._id);

export const canDisconnectConnection = (
  connectionStage: Stage,
  connectionRole: ConnectionRole | undefined,
  user: UserPermissions,
  isReconnecting?: boolean,
  isRequestingEntity?: boolean,
) => {
  if (connectionRole === ConnectionRole.BUYER) {
    return (
      (isReconnecting || connectionStage !== Stage.INVITE) &&
      checkPermissionAndConnectionRole(Permission.DISCONNECT_CONNECTION, connectionRole, user)
    );
  } else if (connectionRole === ConnectionRole.SELLER) {
    return (
      isRequestingEntity &&
      (isReconnecting || connectionStage !== Stage.INVITE) &&
      checkPermissionAndConnectionRole(Permission.DISCONNECT_CONNECTION, connectionRole, user)
    );
  }

  return false;
};
export const userCanDeleteConnection = (
  user: UserPermissions,
  LegacySupplier: boolean,
  userIsBusinessOwner: boolean,
) => {
  if (check(Permission.DISCONNECT_CONNECTION, user)) {
    return true;
  }
  const isAdmin = check(Permission.MENU_ADMIN, user);
  if (LegacySupplier) {
    return isAdmin;
  }
  return isAdmin || userIsBusinessOwner;
};

export const canCompleteAnyConfirmation = (user: UserPermissions) => check(Permission.COMPLETE_ANY_CONFIRMATION, user);

const advancePermission = {
  [Stage.INVITE]: Permission.APPROVE_INVITE_STAGE,
  [Stage.ACCEPT]: Permission.APPROVE_ACCEPT_STAGE,
  [Stage.COLLAB]: Permission.APPROVE_COLLABORATE_STAGE,
  [Stage.CONNECT]: Permission.APPROVE_CONNECT_STAGE,
  [Stage.DISCONNECT]: Permission.DISCONNECT_CONNECTION,
};
export const canAdvanceConnection = (
  connectionStage: Stage,
  connectionRole: ConnectionRole | undefined,
  user: UserPermissions,
) =>
  !advancePermission[connectionStage] ||
  checkPermissionAndConnectionRole(advancePermission[connectionStage], connectionRole, user) ||
  checkPermissionAndConnectionRole(Permission.APPROVE_ANY_CONNECTION_STAGE, connectionRole, user);

export const advanceRole: Partial<Record<Stage, SecurityRole>> = {
  [Stage.INVITE]: SecurityRole.INVITE_BUTTON_OWNER,
  [Stage.ACCEPT]: SecurityRole.ACCEPT_BUTTON_OWNER,
  [Stage.COLLAB]: SecurityRole.COLLABORATE_BUTTON_OWNER,
  [Stage.CONNECT]: SecurityRole.CONNECT_BUTTON_OWNER,
};

// Only users with SecurityRole.GRAPHITE_ADMIN can assign these roles, and they're hidden from the UI
// Add Internal Roles here first, and you'll get type errors for all the other places you need to update
// Note that adding a role here (and its definition in `internalSecurityRoleDefinitions`) will make it
//   so that the role will automatically show up in the Edit User form (in the Admin view) for Graphite Admins.
export const internalSecurityRoles = [
  SecurityRole.RESPONDER_USER,
  SecurityRole.RESPONDER_REPORT_USER,
  SecurityRole.NPS_REPORT_USER,
  SecurityRole.SUPPORT,
] as const;

export type InternalSecurityRoles = typeof internalSecurityRoles[number];

export const graphiteAdminRoles = [
  SecurityRole.GRAPHITE_CSR,
  SecurityRole.GRAPHITE_THOR,
  SecurityRole.GRAPHITE_VALIDATOR,
  SecurityRole.GRAPHITE_INTERFACES,
  SecurityRole.GRAPHITE_OPS,
  SecurityRole.GRAPHITE_SUPER,
  SecurityRole.GRAPHITE_DATA,
];
export const GraphiteRoleDescriptions = {
  [SecurityRole.GRAPHITE_VALIDATOR]: "Validations",
  [SecurityRole.GRAPHITE_OPS]: "System Operations",
  [SecurityRole.GRAPHITE_INTERFACES]: "Integrations",
  [SecurityRole.GRAPHITE_CSR]: "Customer Service",
  [SecurityRole.GRAPHITE_THOR]: "Configuration",
  [SecurityRole.GRAPHITE_SUPER]: "Manage Graphite Admins",
  [SecurityRole.GRAPHITE_DATA]: "Data Management and Exports",
};

export const isGraphiteAdminRole = (role: SecurityRole) => graphiteAdminRoles.indexOf(role) > -1;

export const ssoRestrictedRoles = [
  ...internalSecurityRoles,
  ...graphiteAdminRoles,
  SecurityRole.ACTIVE_USER,
  SecurityRole.EXTERNAL_USER,
  SecurityRole.RELAY_SERVICE,
];

interface InternalSecurityRoleDefinition<K extends InternalSecurityRoles> {
  role: K;
  nameTranslationKey?: string;
  descriptionTranslationKey?: string;
  isGlobal?: boolean; // This role will apply to all entities
  isReadOnly?: boolean; // Cannot edit in the UI
}

export type InternalSecurityRoleDefinitions = {[K in InternalSecurityRoles]: InternalSecurityRoleDefinition<K>};

// update `internalSecurityRoles` first before updating this
export const internalSecurityRoleDefinitions: InternalSecurityRoleDefinitions = {
  [SecurityRole.RESPONDER_USER]: {
    role: SecurityRole.RESPONDER_USER,
    nameTranslationKey: "admin.users.permissions.responder_user",
    descriptionTranslationKey: "admin.users.permissions.responder_user_description",
    // this is a special kind of role. bad things happen if we assign it here, but we still want to be able to view these users
    isReadOnly: true,
  },
  [SecurityRole.RESPONDER_REPORT_USER]: {
    role: SecurityRole.RESPONDER_REPORT_USER,
    nameTranslationKey: "admin.users.permissions.responder_report_user",
    descriptionTranslationKey: "admin.users.permissions.responder_report_user_description",
    isGlobal: true,
  },
  [SecurityRole.NPS_REPORT_USER]: {
    role: SecurityRole.NPS_REPORT_USER,
    nameTranslationKey: "admin.users.permissions.nps_report_user",
    descriptionTranslationKey: "admin.users.permissions.nps_report_user_description",
    isGlobal: true,
  },
  [SecurityRole.SUPPORT]: {
    role: SecurityRole.SUPPORT,
    isGlobal: true,
  },
};

export const onboardingSecurityRoles = [
  SecurityRole.INVITE_BUTTON_OWNER,
  SecurityRole.COLLABORATE_BUTTON_OWNER,
  SecurityRole.CONNECT_BUTTON_OWNER,
  SecurityRole.ACCEPT_BUTTON_OWNER,
] as const;

export type OnboardingSecurityRoles = typeof onboardingSecurityRoles[number];

interface OnboardingSecurityRoleDefinition<K extends OnboardingSecurityRoles> {
  role: K;
  nameTranslationKey: string;
  descriptionTranslationKey: string;
}

export type OnboardingSecurityRoleDefinitions = {[K in OnboardingSecurityRoles]: OnboardingSecurityRoleDefinition<K>};

export const onboardingSecurityRoleDefinitions: OnboardingSecurityRoleDefinitions = {
  [SecurityRole.INVITE_BUTTON_OWNER]: {
    role: SecurityRole.INVITE_BUTTON_OWNER,
    nameTranslationKey: "role.invite",
    descriptionTranslationKey: "admin.users.permissions.invite_description",
  },
  [SecurityRole.COLLABORATE_BUTTON_OWNER]: {
    role: SecurityRole.COLLABORATE_BUTTON_OWNER,
    nameTranslationKey: "role.collaborate",
    descriptionTranslationKey: "admin.users.permissions.collaborate_description",
  },
  [SecurityRole.CONNECT_BUTTON_OWNER]: {
    role: SecurityRole.CONNECT_BUTTON_OWNER,
    nameTranslationKey: "role.connect",
    descriptionTranslationKey: "admin.users.permissions.connect_description",
  },
  [SecurityRole.ACCEPT_BUTTON_OWNER]: {
    role: SecurityRole.ACCEPT_BUTTON_OWNER,
    nameTranslationKey: "role.accept",
    descriptionTranslationKey: "admin.users.permissions.accept_description",
  },
};

export const coreSystemSecurityRoles = [
  SecurityRole.ADMIN,
  SecurityRole.IT_ADMIN,
  SecurityRole.SYSTEM_ADMIN,
  SecurityRole.BANKING,
  SecurityRole.CANCEL_CONNECTION,
  SecurityRole.TAX,
  SecurityRole.CAMPAIGNS,
  SecurityRole.BULK_SUPPLIER_UPDATES,
] as const;

export const conditionalSystemSecurityRoles = [SecurityRole.BULK_COMMUNICATIONS] as const;

export type CoreSystemSecurityRoles = typeof coreSystemSecurityRoles[number];
export type ConditionalSystemSecurityRoles = typeof conditionalSystemSecurityRoles[number];
export type SystemSecurityRoles = CoreSystemSecurityRoles | ConditionalSystemSecurityRoles;

interface SystemSecurityRoleDefinition<K extends SystemSecurityRoles> {
  role: K;
  nameTranslationKey: string;
  descriptionTranslationKey: string;
  disabled?: boolean;
}

export type SystemSecurityRoleDefinitions = {
  [K in CoreSystemSecurityRoles]: SystemSecurityRoleDefinition<K>;
} &
  {
    [L in ConditionalSystemSecurityRoles]?: SystemSecurityRoleDefinition<L>;
  };

export function getSystemSecurityRoleDefinitions(
  features: {[feature in EntityFeature]?: boolean} = {},
): SystemSecurityRoleDefinitions {
  const roleDefs: SystemSecurityRoleDefinitions = {
    [SecurityRole.ADMIN]: {
      nameTranslationKey: "admin.users.permissions.admin",
      descriptionTranslationKey: "admin.users.permissions.admin_description",
      role: SecurityRole.ADMIN,
    },
    [SecurityRole.SYSTEM_ADMIN]: {
      nameTranslationKey: "admin.users.permissions.system_admin",
      descriptionTranslationKey: "admin.users.permissions.system_admin_description",
      role: SecurityRole.SYSTEM_ADMIN,
    },
    [SecurityRole.IT_ADMIN]: {
      nameTranslationKey: "admin.users.permissions.it_admin",
      descriptionTranslationKey: "admin.users.permissions.it_admin_description",
      role: SecurityRole.IT_ADMIN,
    },
    [SecurityRole.BANKING]: {
      nameTranslationKey: "admin.users.permissions.banking",
      descriptionTranslationKey: "admin.users.permissions.banking_description",
      role: SecurityRole.BANKING,
    },
    [SecurityRole.CANCEL_CONNECTION]: {
      nameTranslationKey: "admin.users.permissions.cancel",
      descriptionTranslationKey: "admin.users.permissions.cancel_description",
      role: SecurityRole.CANCEL_CONNECTION,
    },
    [SecurityRole.TAX]: {
      nameTranslationKey: "admin.users.permissions.tax",
      descriptionTranslationKey: "admin.users.permissions.tax_description",
      role: SecurityRole.TAX,
    },
    [SecurityRole.CAMPAIGNS]: {
      nameTranslationKey: "admin.users.permissions.campaigns",
      descriptionTranslationKey: "admin.users.permissions.campaigns_description",
      role: SecurityRole.CAMPAIGNS,
    },
    ...(features?.[EntityFeature.BULK_MESSAGE]
      ? {
          [SecurityRole.BULK_COMMUNICATIONS]: {
            nameTranslationKey: "admin.users.permissions.bulk_communications",
            descriptionTranslationKey: "admin.users.permissions.bulk_communications_description",
            role: SecurityRole.BULK_COMMUNICATIONS,
          },
        }
      : {}),
    [SecurityRole.BULK_SUPPLIER_UPDATES]: {
      nameTranslationKey: "admin.users.permissions.bulk_supplier_updates",
      descriptionTranslationKey: "admin.users.permissions.bulk_supplier_updates_description",
      role: SecurityRole.BULK_SUPPLIER_UPDATES,
    },
  };

  return roleDefs;
}

export function userHasRoleOrEmptyList(activeEntityRoles: Set<string>, roleIds: string[] | undefined): boolean {
  if (!roleIds || roleIds.length === 0) {
    return true;
  }
  for (const role of roleIds) {
    if (activeEntityRoles.has(role)) {
      return true;
    }
  }
  return false;
}
