import cloneDeep from "lodash/cloneDeep";
import type {Ref, WritableComputedRef} from "vue";
import {computed, ref} from "vue";
import type {ListUser} from "pg-isomorphic/api/user";

const namespace = "GRAPHITE";

export enum StorageKey {
  CONTACT_OPTIONS = "contact_options",
  USER_OPTIONS = "user_options",
}

export type StorageItem<T> = WritableComputedRef<T> & {reset(): void};

export interface StorageTypes {
  [storageKey: string]: ListUser[];
}

export function storageComputed<K extends StorageKey>(
  key: K,
  defaultVal: StorageTypes[K],
): StorageItem<StorageTypes[K]>;
export function storageComputed<K extends StorageKey>(key: K): StorageItem<StorageTypes[K] | null>;
export function storageComputed<K extends StorageKey>(
  key: K,
  defaultVal?: StorageTypes[K],
): StorageItem<StorageTypes[K] | null> {
  let initialValue = getFromStorage<K>(key);
  if (initialValue === null && defaultVal !== undefined) {
    initialValue = defaultVal;
  }
  const internalVal: Ref<StorageTypes[K] | null> = ref(cloneDeep(initialValue));

  const item = computed<StorageTypes[K] | null>({
    get(): StorageTypes[K] | null {
      return internalVal.value;
    },
    set(val: StorageTypes[K] | null) {
      internalVal.value = val;
      saveInStorage(key, val);
    },
  }) as StorageItem<StorageTypes[K] | null>;

  item.reset = () => {
    removeFromStorage(key);
    internalVal.value = cloneDeep(defaultVal || null);
  };

  return item;
}

export function getFromStorage<K extends StorageKey>(key: string): StorageTypes[K] | null {
  const val = localStorage.getItem(`${namespace}::${key}`);
  if (!val) {
    return null;
  }

  try {
    return JSON.parse(val);
  } catch {
    return null;
  }
}

export function saveInStorage<K extends StorageKey>(key: string, val: StorageTypes[K] | null): void {
  if (val === null) {
    removeFromStorage(key);
  } else {
    localStorage.setItem(`${namespace}::${key}`, JSON.stringify(val));
  }
}

export function removeFromStorage(key: string): void {
  localStorage.removeItem(`${namespace}::${key}`);
}

export function clearStorage() {
  localStorage.clear();
}
